import { type KeyboardEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

const TrackingIdInputForm = () => {
    const [trackingId, setTrackingId] = useState('');
    const intl = useIntl();
    const navigate = useNavigate();
    const navigateToTrackingId = () => trackingId && navigate(trackingId);

    const trackingIdChangeHandler = (event: { target: { value: string } }) => {
        setTrackingId(event.target.value);
    };
    const buttonClickHandler = () => {
        navigateToTrackingId();
    };
    const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            navigateToTrackingId();
        }
    };
    return (
        <div className='panel panel-default panel-body padding-20 shadow-smooth'>
            <div className='text-center'>
                <div className='text-size-h2 text-color-light'>
                    <span className='rioglyph rioglyph-delivery-on-track text-size-300pct' />
                </div>
                <div className='text-size-16 text-medium line-height-125rel text-color-dark margin-top-10'>
                    <FormattedMessage id='intl-msg:tracking.trackDelivery' />
                </div>
            </div>
            <div className='display-flex justify-content-center margin-top-25'>
                <div className='form-group width-100pct max-width-500'>
                    <div className='input-group'>
                        <input
                            className='form-control'
                            placeholder={intl.formatMessage({ id: 'intl-msg:tracking.enterTrackingId' })}
                            value={trackingId}
                            onChange={trackingIdChangeHandler}
                            onKeyDown={keyDownHandler}
                        />
                        <span className='input-group-btn'>
                            <button className='btn btn-primary' type='button' onClick={buttonClickHandler}>
                                <span className='rioglyph rioglyph-search' />
                                <FormattedMessage id='intl-msg:tracking.search' />
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackingIdInputForm;
