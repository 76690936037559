import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config';
import type { ApiDeliveryStatus, DeliveryStatus } from './deliveryStatus.types';
import { mapDeliveryStatus } from './deliveryStatusMapper';

const HTTP_UNAUTHORIZED = 401;

const handleResponse = async (response: Response) => {
    if (response.ok) {
        try {
            return response.json();
        } catch (error) {
            throw new Error(`${response.status} Invalid payload: ${error}`);
        }
    }
    if (response.status === HTTP_UNAUTHORIZED) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};

export const deliveryStatusApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.DELIVERY_STATUS_SERVICE,
    }),
    tagTypes: ['DeliveryStatus'],
    endpoints: builder => ({
        fetchDeliveryStatus: builder.query<DeliveryStatus, string>({
            query: trackingId => ({
                url: `/delivery-status/${trackingId}`,
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiDeliveryStatus) => mapDeliveryStatus(response),
            providesTags: ['DeliveryStatus'],
        }),
    }),
    refetchOnFocus: true,
});

export const { useFetchDeliveryStatusQuery } = deliveryStatusApi;
