import { FormattedMessage } from 'react-intl';
import { type DeliveryStatus, Status } from '../../services/deliveryStatus/deliveryStatus.types';
import MiniMap from './MiniMap';

interface DeliveryStatusPanelProps {
    deliveryStatus: DeliveryStatus;
}

const DeliveryStatusPanel = ({ deliveryStatus }: DeliveryStatusPanelProps) => (
    <div className='panel panel-default display-grid grid-cols-1 grid-cols-2-sm gap-20 shadow-smooth'>
        <div className='flex-order-2-sm padding-15-sm'>
            <div className='height-400 height-300-xs'>
                <MiniMap
                    delivered={deliveryStatus?.status === Status.DELIVERED}
                    latestPosition={deliveryStatus?.latestPosition}
                    placeOfLoadingPosition={deliveryStatus?.placeOfLoading?.position}
                    placeOfDeliveryPosition={deliveryStatus?.placeOfDelivery?.position}
                />
            </div>
        </div>
        <div className='flex-order-1-sm flex-1-1-0 padding-25'>
            <div className='display-grid grid-cols-1 grid-cols-2-sm gap-20'>
                {deliveryStatus?.externalReferences?.map(externalReference => {
                    return (
                        externalReference.value && (
                            <div key={externalReference.name}>
                                <label className='text-size-16'>{externalReference.name}</label>
                                <div className='text-size-18 text-bold'>{externalReference.value}</div>
                            </div>
                        )
                    );
                })}
            </div>
            <hr />
            <div className='display-grid grid-cols-1 grid-cols-2-sm gap-20'>
                {deliveryStatus?.placeOfLoading?.name && (
                    <div>
                        <label className='text-size-16'>
                            <span className='rioglyph rioglyph-start margin-right-5' />
                            <FormattedMessage id='intl-msg:tracking.deliveryStatus.placeOfLoading' />
                        </label>
                        <div className='text-size-18 text-bold'>{deliveryStatus?.placeOfLoading?.name}</div>
                    </div>
                )}
                <div>
                    <label className='text-size-16'>
                        <span className='rioglyph rioglyph-finish margin-right-5' />
                        <FormattedMessage id='intl-msg:tracking.deliveryStatus.placeOfDelivery' />
                    </label>
                    <div className='text-size-18 text-bold'>{deliveryStatus?.placeOfDelivery?.name}</div>
                </div>
            </div>
            <hr />
            <div className='display-grid grid-cols-1 grid-cols-2-sm gap-20'>
                <div>
                    <label className='text-size-16'>
                        <FormattedMessage id='intl-msg:tracking.deliveryStatus.load' />
                    </label>
                    <div className='text-size-18 text-bold'>{deliveryStatus?.goods?.description}</div>
                </div>
            </div>
        </div>
    </div>
);

export default DeliveryStatusPanel;
