import { FormattedMessage, IntlProvider } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import DeliveryStatus from '../pages/DeliveryStatus';
import TrackingIdInputForm from '../pages/TrackingIdInputForm';
import DeliveryStatusSurvey from '../features/survey/DeliveryStatusSurvey';

const App = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    if (!displayMessages || !userLocale) {
        return null;
    }

    return (
        <IntlProvider
            defaultLocale={DEFAULT_LOCALE}
            key={userLocale}
            locale={DEFAULT_LOCALE}
            messages={displayMessages}
        >
            <ApplicationLayout className={'DeliveryStatus'}>
                <ApplicationLayout.Body className='overflow-hidden'>
                    <NotificationsContainer />
                    <div className='container fluid-large padding-x-5pct-sm'>
                        <div className='padding-y-5pct text-size-h2 text-center text-bold'>
                            <FormattedMessage id='intl-msg:tracking.deliveryStatus.yourDeliveryStatus' />
                        </div>
                        <Routes>
                            <Route path='/' element={<TrackingIdInputForm />} />
                            <Route path='/:trackingId' element={<DeliveryStatus />} />
                            <Route path='*' element={<Navigate to='/' replace />} />
                        </Routes>
                        <DeliveryStatusSurvey />
                    </div>
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default App;
