import { type Middleware, configureStore } from '@reduxjs/toolkit';

import configReducer from './configSlice';
import langReducer from '../lang/langSlice';
import appReducer from '../../layout/appSlice';
import { deliveryStatusApi } from '../../services/deliveryStatus/deliveryStatusApi';

// Adding the api middleware enables caching, invalidation, polling,
// and other useful features of `rtk-query`.
export const middleware = (getDefaultMiddleware: () => Middleware[]) =>
    getDefaultMiddleware().concat([deliveryStatusApi.middleware]);

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        // Add the generated reducer as a specific top-level slice
        [deliveryStatusApi.reducerPath]: deliveryStatusApi.reducer,
    },
    middleware,
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;