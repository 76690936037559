import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IframeResizer from 'iframe-resizer-react';
import Dialog from '@rio-cloud/rio-uikit/Dialog';

const ALLOWED_DOMAINS = ['localhost', 'delivery-status.rio.cloud'];

const DeliveryStatusSurveyWidget = () => (
    <IframeResizer
        src={'feedback.html'}
        checkOrigin={false}
        resizeFrom={'child'}
        bodyMargin={'0 0 20px 0'}
        style={{ width: '1px', minWidth: '100%', minHeight: '700px', border: '0' }}
    />
);

const DeliveryStatusSurvey = () => {
    const intl = useIntl();
    const [dialogVisible, setDialogVisible] = useState(false);
    const domain = window.location.hostname;

    if (!ALLOWED_DOMAINS.includes(domain)) {
        return null;
    }

    const showDialog = () => setDialogVisible(true);
    const hideDialog = () => setDialogVisible(false);

    return (
        <>
            <Dialog
                show={dialogVisible}
                title={intl.formatMessage({ id: 'intl-msg:tracking.survey.title' })}
                showCloseButton
                onHide={hideDialog}
                body={<DeliveryStatusSurveyWidget />}
            />
            <div className='panel panel-default panel-body shadow-smooth text-center'>
                <div className='text-size-h3 text-medium text-color-darker'>
                    <FormattedMessage id='intl-msg:tracking.survey.welcome' />
                </div>

                <button type='button' className='btn btn-link btn-lg btn-multiline' onClick={showDialog}>
                    <span className='rioglyph rioglyph-chat' />
                    <FormattedMessage id='intl-msg:tracking.survey.cta' />
                </button>
            </div>
        </>
    );
};

export default DeliveryStatusSurvey;
