import {
    type ApiDeliveryStatus,
    type ApiEstimatedArrival,
    type ApiExternalReference,
    type ApiGoods,
    type ApiPlace,
    type ApiPosition,
    type DeliveryStatus,
    type EstimatedArrival,
    type ExternalReference,
    type Goods,
    type Place,
    type Position,
    Status,
} from './deliveryStatus.types';

const enumFromStringValue = <T>(enm: { [s: string]: T }, value: string): T | undefined => {
    return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
};

export const mapStatus = (status: string): Status | undefined => {
    return enumFromStringValue(Status, status);
};

export const mapExternalReference = (apiExternalReference: ApiExternalReference): ExternalReference => {
    const { name, value } = apiExternalReference;
    return {
        name,
        value,
    };
};

export const mapPosition = (apiPosition: ApiPosition): Position => {
    const { latitude, longitude } = apiPosition;
    return {
        latitude,
        longitude,
    };
};

export const mapPlace = (apiPlace: ApiPlace): Place => {
    const { position, name } = apiPlace;

    return {
        position: mapPosition(position),
        name,
    };
};

export const mapGoods = (apiGoods: ApiGoods): Goods => {
    const { description } = apiGoods;
    return { description };
};

export const mapEstimatedArrival = (apiEstimatedArrival: ApiEstimatedArrival): EstimatedArrival => {
    const { distance_in_meters, arrival_at, arrival_window_start_at, arrival_window_end_at } = apiEstimatedArrival;
    return {
        distanceInMeters: distance_in_meters,
        arrivalAt: arrival_at,
        arrivalWindowStartAt: arrival_window_start_at,
        arrivalWindowEndAt: arrival_window_end_at,
    };
};

export const mapDeliveryStatus = (apiDeliveryStatus: ApiDeliveryStatus): DeliveryStatus => {
    const {
        id,
        status,
        external_references,
        place_of_loading,
        place_of_delivery,
        latest_position,
        goods,
        delivered_at,
        estimated_arrival,
    } = apiDeliveryStatus;
    return {
        id,
        // biome-ignore lint/style/noNonNullAssertion: Can we do it better?
        status: mapStatus(status)!,
        externalReferences: external_references?.map(mapExternalReference),
        placeOfLoading: place_of_loading && mapPlace(place_of_loading),
        placeOfDelivery: place_of_delivery && mapPlace(place_of_delivery),
        latestPosition: latest_position && mapPosition(latest_position),
        goods: goods && mapGoods(goods),
        deliveredAt: delivered_at,
        estimatedArrival: estimated_arrival && mapEstimatedArrival(estimated_arrival),
    };
};
