import * as t from 'io-ts';

export enum Status {
    ANNOUNCED = 'announced',
    IN_TRANSIT = 'in-transit',
    DELIVERED = 'delivered',
}

export interface ExternalReference {
    name: string;
    value: string;
}

export interface Position {
    latitude: number;
    longitude: number;
}

export interface Place {
    position: Position;
    name?: string;
}

export interface Goods {
    description: string;
}

export interface EstimatedArrival {
    distanceInMeters: number;
    arrivalAt: string;
    arrivalWindowStartAt?: string;
    arrivalWindowEndAt?: string;
}

export interface DeliveryStatus {
    id: string;
    status: Status;
    externalReferences?: ExternalReference[];
    placeOfLoading?: Place;
    placeOfDelivery?: Place;
    latestPosition?: Position;
    goods?: Goods;
    deliveredAt?: string; // TODO datetime
    estimatedArrival?: EstimatedArrival;
}

const ExternalReferenceCodec = t.type({
    name: t.string,
    value: t.string,
});

const PositionCodec = t.type({
    latitude: t.number,
    longitude: t.number,
});

const PlaceCodec = t.intersection([
    t.type({
        position: PositionCodec,
    }),
    t.partial({
        name: t.string,
    }),
]);

const GoodsCodec = t.type({
    description: t.string,
});

const EstimatedArrivalCodec = t.intersection([
    t.type({
        distance_in_meters: t.number,
        arrival_at: t.string,
    }),
    t.partial({
        arrival_window_start_at: t.string,
        arrival_window_end_at: t.string,
    }),
]);

const DeliveryStatusCodec = t.intersection([
    t.type({
        id: t.string,
        status: t.string,
    }),
    t.partial({
        external_references: t.array(ExternalReferenceCodec),
        place_of_loading: PlaceCodec,
        place_of_delivery: PlaceCodec,
        latest_position: PositionCodec,
        goods: GoodsCodec,
        delivered_at: t.string,
        estimated_arrival: EstimatedArrivalCodec,
    }),
]);

export type ApiExternalReference = t.TypeOf<typeof ExternalReferenceCodec>;
export type ApiPosition = t.TypeOf<typeof PositionCodec>;
export type ApiPlace = t.TypeOf<typeof PlaceCodec>;
export type ApiGoods = t.TypeOf<typeof GoodsCodec>;
export type ApiEstimatedArrival = t.TypeOf<typeof EstimatedArrivalCodec>;
export type ApiDeliveryStatus = t.TypeOf<typeof DeliveryStatusCodec>;
