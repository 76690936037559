import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

import { useFetchDeliveryStatusQuery } from '../services/deliveryStatus/deliveryStatusApi';
import DeliveryStatusPanel from '../features/deliveryStatus/DeliveryStatusPanel';
import DeliveryStatusSteps from '../features/deliveryStatus/DeliveryStatusSteps';

type DeliveryStatusParams = { trackingId: string };

const pollingInterval = 20e3; // 20 seconds

const DeliveryStatus = () => {
    const { trackingId } = useParams<'trackingId'>() as DeliveryStatusParams;
    const { data, error, isLoading } = useFetchDeliveryStatusQuery(trackingId, { pollingInterval });

    if (isLoading) {
        return (
            <div className='height-100vh'>
                <Spinner isDoubleSized />
            </div>
        );
    }

    if (error || !data) {
        return (
            <div className='panel panel-default shadow-smooth'>
                <EmptyState
                    headline={<FormattedMessage id='intl-msg:tracking.error.noDeliveryStatus' />}
                    message={<FormattedMessage id='intl-msg:tracking.error.noDeliveryStatusPhrase' />}
                    outerClassName='border-none margin-y-20'
                />
            </div>
        );
    }

    return (
        <>
            <DeliveryStatusSteps deliveryStatus={data} />
            <DeliveryStatusPanel deliveryStatus={data} />
        </>
    );
};

export default DeliveryStatus;
