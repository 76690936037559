import { FormattedMessage, useIntl } from 'react-intl';
import isNil from 'lodash/fp/isNil';
import isSameDay from 'date-fns/isSameDay';

import { type DeliveryStatus, type EstimatedArrival, Status } from '../../services/deliveryStatus/deliveryStatus.types';

interface StepProps {
    stepName: string;
    iconName?: string;
    isActive?: boolean;
    progressBar?: React.ReactNode;
    children: React.ReactNode;
}

interface ProgressBarProps {
    isActive?: boolean;
    isRunning?: boolean;
}

interface DeliveryStatusStepsProps {
    deliveryStatus: DeliveryStatus;
}

interface DeliveryDetailsProps {
    isInTransit: boolean;
    isComingUp: boolean;
    isDelivered: boolean;
    estimatedArrival: EstimatedArrival | null;
    deliveredAt: string | undefined;
}

const KILOMETER_IN_METER = 1000;
const DISTANCE_PRECISION = 1;

const DATE_OPTIONS = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'long',
} as Intl.DateTimeFormatOptions;

const DATE_ONLY_OPTIONS = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    weekday: 'long',
} as Intl.DateTimeFormatOptions;

const TIME_OPTIONS = {
    hour: 'numeric',
    minute: 'numeric',
} as Intl.DateTimeFormatOptions;

const getEstimatedArrival = (deliveryStatus: DeliveryStatus | undefined) =>
    !isNil(deliveryStatus) && deliveryStatus.estimatedArrival ? deliveryStatus.estimatedArrival : null;

const StepIcon = ({ isActive, iconName }: { isActive: boolean; iconName: string }) => (
    <div
        className={`rounded-circle ${
            isActive ? 'bg-primary' : 'bg-white'
        } width-50 height-50 display-grid place-items-center position-relative z-index-1`}
    >
        <span
            className={`rioglyph rioglyph-${iconName} ${
                isActive ? 'text-color-white' : 'text-color-primary'
            } text-size-20`}
        />
    </div>
);

const Step = (props: StepProps) => {
    const { stepName, iconName = 'delivery', isActive = false, progressBar, children } = props;
    return (
        <>
            <div
                className='display-flex flex-column align-items-center gap-20 position-relative z-index-1 width-50'
                data-testid={stepName}
            >
                <StepIcon isActive={isActive} iconName={iconName} />
                <div>{children}</div>
            </div>
            {progressBar ? <div className='position-relative flex-1-1-0'>{progressBar}</div> : null}
        </>
    );
};

const MobileStep = (props: StepProps) => {
    const { stepName, iconName = 'delivery', isActive = false, progressBar, children } = props;
    return (
        <div className='overflow-hidden' data-testid={stepName}>
            <StepIcon isActive={isActive} iconName={iconName} />
            <div className={`padding-left-25 position-relative display-flex ${progressBar ? 'padding-bottom-25' : ''}`}>
                {progressBar}
                <div className='padding-left-50'>
                    <div className='margin-top--50 padding-top-3'>{children}</div>
                </div>
            </div>
        </div>
    );
};

const ProgressBar = (props: ProgressBarProps) => {
    const { isActive = false, isRunning = false } = props;

    const additionalClassNames = [
        isActive && !isRunning && 'width-100pct',
        isRunning && 'progress-bar-striped progress-bar-animated width-50pct',
    ].join(' ');

    return (
        <>
            <div className='hidden-xs position-absolute progress top-25 translate-y-50pct left--5 right--5 bg-white'>
                <div className={`progress-bar progress-bar-primary ${additionalClassNames}`} />
            </div>

            <div className='visible-xs position-absolute top-0 bottom-0 width-10 translate-x-50pct'>
                <div
                    className='progress bg-white transform-origin-left-top rotate-270 width-200 position-relative'
                    style={{ top: 'calc(100% + 10px)' }}
                >
                    <div className={`progress-bar progress-bar-primary ${additionalClassNames}`} />
                </div>
            </div>
        </>
    );
};

const LoadingMessage = () => {
    return <FormattedMessage id='intl-msg:tracking.deliveryStatus.loading' />;
};

const TransitMessage = ({ estimatedArrival }: { estimatedArrival: EstimatedArrival | null }) => (
    <div className='white-space-nowrap'>
        <FormattedMessage id='intl-msg:tracking.deliveryStatus.inDelivery' />
        {!!estimatedArrival?.distanceInMeters && (
            <div className='text-bold text-size-18-sm'>
                <FormattedMessage id='intl-msg:tracking.deliveryStatus.distance' />
                {` ${(estimatedArrival.distanceInMeters / KILOMETER_IN_METER).toFixed(DISTANCE_PRECISION)} km`}
            </div>
        )}
    </div>
);

const DeliveredMessage = ({ deliveryDate }: { deliveryDate: string | undefined }) => (
    <>
        <FormattedMessage id='intl-msg:tracking.deliveryStatus.delivered' />
        <div className='text-bold text-size-18-sm'>
            {deliveryDate && new Date(deliveryDate.toString()).toLocaleDateString(useIntl().locale, DATE_OPTIONS)}
            <FormattedMessage id='intl-msg:tracking.oClock' />
        </div>
    </>
);

const EstimatedArrivalMessage = ({ estimatedArrival }: { estimatedArrival: EstimatedArrival }) => {
    const { arrivalAt, arrivalWindowStartAt, arrivalWindowEndAt } = estimatedArrival;

    const arrivalDate = new Date(arrivalAt.toString());
    const arrivalDateStart = arrivalWindowStartAt && new Date(arrivalWindowStartAt.toString());
    const arrivalDateEnd = arrivalWindowEndAt && new Date(arrivalWindowEndAt.toString());

    const today = new Date();
    const isSameDayAsEstimated = arrivalDateEnd && isSameDay(today, arrivalDateEnd);

    const hasRange = arrivalDateStart && arrivalDateEnd;

    return (
        <>
            <FormattedMessage id='intl-msg:tracking.deliveryStatus.estimatedArrival' />
            <div className='text-bold text-size-18-sm'>
                {hasRange && (
                    <>
                        {!isSameDayAsEstimated && (
                            <div>{arrivalDateEnd.toLocaleDateString(useIntl().locale, DATE_ONLY_OPTIONS)}</div>
                        )}
                        {arrivalDateStart.toLocaleTimeString(useIntl().locale, TIME_OPTIONS)}
                        {' - '}
                        {arrivalDateEnd.toLocaleTimeString(useIntl().locale, TIME_OPTIONS)}{' '}
                        <FormattedMessage id='intl-msg:tracking.oClock' />
                    </>
                )}
                {!hasRange && isSameDayAsEstimated && (
                    <span>
                        {arrivalDate.toLocaleTimeString(useIntl().locale, TIME_OPTIONS)}{' '}
                        <FormattedMessage id='intl-msg:tracking.oClock' />
                    </span>
                )}
                {!hasRange && !isSameDayAsEstimated && (
                    <span>
                        {arrivalDate.toLocaleDateString(useIntl().locale, DATE_OPTIONS)}{' '}
                        <FormattedMessage id='intl-msg:tracking.oClock' />
                    </span>
                )}
            </div>
        </>
    );
};

const DesktopDeliveryStatusSteps = ({
    isInTransit,
    isComingUp,
    isDelivered,
    estimatedArrival,
    deliveredAt,
}: DeliveryDetailsProps) => (
    <div className='margin-x-10pct hidden-xs'>
        <div
            className={`position-relative display-flex align-items-start margin-top-10 margin-bottom-5pct 
                    text-center line-height-125rel`}
        >
            <Step
                stepName='step-1'
                iconName='start'
                isActive
                progressBar={<ProgressBar isActive={isInTransit || isDelivered} />}
            >
                <LoadingMessage />
            </Step>

            <Step
                stepName='step-2'
                iconName={isInTransit ? 'delivery-on-track' : 'delivery'}
                isActive={isInTransit || isDelivered}
                progressBar={<ProgressBar isActive={isDelivered} isRunning={!isComingUp && isInTransit} />}
            >
                <TransitMessage estimatedArrival={estimatedArrival} />
            </Step>

            <Step stepName='step-3' iconName={isDelivered ? 'ok' : 'finish'} isActive={isDelivered}>
                <div className='white-space-nowrap'>
                    {isDelivered && <DeliveredMessage deliveryDate={deliveredAt} />}
                    {!isDelivered && estimatedArrival && (
                        <EstimatedArrivalMessage estimatedArrival={estimatedArrival} />
                    )}
                </div>
            </Step>
        </div>
    </div>
);

const MobileDeliveryStatusSteps = ({
    isInTransit,
    isComingUp,
    isDelivered,
    estimatedArrival,
    deliveredAt,
}: DeliveryDetailsProps) => (
    <div className='padding-y-25 margin-bottom-25 visible-xs'>
        <MobileStep
            stepName='mobile-step-1'
            iconName='start'
            isActive
            progressBar={<ProgressBar isActive={isInTransit || isDelivered} />}
        >
            <LoadingMessage />
        </MobileStep>

        <MobileStep
            stepName='mobile-step-2'
            iconName={isInTransit ? 'delivery-on-track' : 'delivery'}
            isActive={isInTransit || isDelivered}
            progressBar={<ProgressBar isActive={isDelivered} isRunning={!isComingUp && isInTransit} />}
        >
            <TransitMessage estimatedArrival={estimatedArrival} />
        </MobileStep>

        <MobileStep stepName='mobile-step-3' iconName={isDelivered ? 'ok' : 'finish'} isActive={isDelivered}>
            <div className='white-space-nowrap'>
                {isDelivered && <DeliveredMessage deliveryDate={deliveredAt} />}
                {!isDelivered && estimatedArrival && <EstimatedArrivalMessage estimatedArrival={estimatedArrival} />}
            </div>
        </MobileStep>
    </div>
);

const DeliveryStatusSteps = ({ deliveryStatus }: DeliveryStatusStepsProps) => {
    const isInTransit = deliveryStatus?.status === Status.IN_TRANSIT;
    const isComingUp = isInTransit && !deliveryStatus?.latestPosition;
    const isDelivered = deliveryStatus?.status === Status.DELIVERED;

    const estimatedArrival = getEstimatedArrival(deliveryStatus);

    return (
        <>
            <DesktopDeliveryStatusSteps
                isInTransit={isInTransit}
                isComingUp={isComingUp}
                isDelivered={isDelivered}
                estimatedArrival={estimatedArrival}
                deliveredAt={deliveryStatus?.deliveredAt}
            />
            <MobileDeliveryStatusSteps
                isInTransit={isInTransit}
                isComingUp={isComingUp}
                isDelivered={isDelivered}
                estimatedArrival={estimatedArrival}
                deliveredAt={deliveryStatus?.deliveredAt}
            />
        </>
    );
};

export default DeliveryStatusSteps;
