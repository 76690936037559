const localStorage = window.localStorage;

const STORAGE_PREFIX = 'deliverystatus.';

// biome-ignore lint/suspicious/noExplicitAny: Can we improve it, check other repos
const save = (key: string, value: any, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
        return value;
    } catch (_) {
        // Intentionally left blank
    }
};

const removeItem = (key: string, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.removeItem(`${prefix}${key}`);
    } catch (_) {
        // Intentionally left blank
    }
};

const load = (key: string, prefix = STORAGE_PREFIX) => {
    try {
        // biome-ignore lint/style/noNonNullAssertion: We do it anyways
        return JSON.parse(localStorage.getItem(`${prefix}${key}`)!);
    } catch (_) {
        // Intentionally left blank
    }
};

export const storage = { save, load, removeItem };
