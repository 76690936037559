import { DEFAULT_LOCALE, extractLanguage } from './lang/lang';
import { configureFetchDisplayMessages } from './lang/services';
import { trace } from './setup/trace';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { getLocale } from './lang/langSlice';

export const main = async (renderApp: () => void) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    // TODO Do not use default locale but fetch it some other way
    await fetchDisplayMessages(DEFAULT_LOCALE);

    try {
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
