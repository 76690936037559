import qs from 'qs';
import { storage } from './storage';

const PREFIX = 'featureToggle.';

const detectFeatureToggles = () => {
    // biome-ignore lint/complexity/noBannedTypes: We need to define transform
    const detectToggle = (transform: Function, name: string) => {
        const [_, searchParams = ''] = window.location.hash.split('?');
        const params = qs.parse(searchParams);

        const toggleValue = params[name];
        const value = toggleValue ? storage.save(name, toggleValue, PREFIX) : storage.load(name, PREFIX);

        return value && transform(value);
    };

    const BooleanToggle = (value: string) => {
        return value === 'true' || value === '1';
    };

    return {
        stopDetectionRadius: detectToggle(String, 'ft_sdr'),
    };
};

export const featureToggles = detectFeatureToggles();
