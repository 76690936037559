import isNil from 'lodash/fp/isNil';
import compact from 'lodash/fp/compact';
import isEmpty from 'lodash/fp/isEmpty';

import Circle from '@rio-cloud/rio-uikit/Circle';
// biome-ignore lint/suspicious/noShadowRestrictedNames: We do it anyways
import Map from '@rio-cloud/rio-uikit/Map';
import Marker from '@rio-cloud/rio-uikit/Marker';
import SingleMapMarker from '@rio-cloud/rio-uikit/SingleMapMarker';

import type { Position } from '../../services/deliveryStatus/deliveryStatus.types';
import { config } from '../../config';
import { featureToggles } from '../../configuration/setup/featureToggles';

interface MiniMapProps {
    delivered: boolean;
    latestPosition?: Position;
    placeOfLoadingPosition?: Position;
    placeOfDeliveryPosition?: Position;
}

interface BoundingBox {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

const credentials = {
    apikey: config.hereApiKey,
};

const DEFAULT_BOUNDING_BOX: BoundingBox = {
    top: 50.113906,
    left: 8.475672,
    bottom: 50.06746,
    right: 8.529771,
};

const BOUNDING_BOX_THRESHOLD: BoundingBox = {
    top: 0.02,
    left: -0.03,
    bottom: -0.02,
    right: 0.03,
};

const BOUNDING_BOX_INCREASE_FACTORS: BoundingBox = {
    top: 0.82,
    left: 0.7,
    bottom: 0.58,
    right: 0.7,
};

const EARTH_RADIUS = 6378.388;

const TWO = 2;

const calculateBoundingBox = (positions: Position[]): BoundingBox => {
    if (isEmpty(positions)) {
        return DEFAULT_BOUNDING_BOX;
    }
    const { top, left, bottom, right } = positions.reduce(
        (box, position) => {
            return {
                top: Math.max(box.top, position.latitude),
                left: Math.max(box.left, position.longitude),
                bottom: Math.min(box.bottom, position.latitude),
                right: Math.min(box.right, position.longitude),
            };
        },
        {
            top: -90,
            left: -180,
            bottom: 90,
            right: 180,
        }
    );
    return {
        top: top === bottom ? top + BOUNDING_BOX_THRESHOLD.top : top,
        left: left === right ? left + BOUNDING_BOX_THRESHOLD.left : left,
        bottom: top === bottom ? bottom + BOUNDING_BOX_THRESHOLD.bottom : bottom,
        right: left === right ? right + BOUNDING_BOX_THRESHOLD.right : right,
    };
};

const calculateDistance = (lat1: number, lat2: number, lng1: number, lng2: number) => {
    /* Distance between upper left to lower right corner of bounding box
       https://www.kompf.de/gps/distcalc.html */
    return (
        EARTH_RADIUS *
        Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1))
    );
};

const calculateMaxBoundingBoxSide = (boundingBox: BoundingBox) =>
    Math.max(
        calculateDistance(boundingBox.top, boundingBox.top, boundingBox.left, boundingBox.right),
        calculateDistance(boundingBox.top, boundingBox.bottom, boundingBox.left, boundingBox.left)
    );

const getBoundingBox = (deliveryPos: Position | undefined, loadingPos?: Position, latestPos?: Position) => {
    const positions = compact([deliveryPos, loadingPos, latestPos])
        .filter(pos => !isNil(pos))
        // biome-ignore lint/style/noNonNullAssertion: Maybe find a better way to do this
        // biome-ignore lint/suspicious/noExtraNonNullAssertion: Maybe find a better way to do this
        .map(pos => pos!!);

    return isEmpty(positions) ? DEFAULT_BOUNDING_BOX : calculateBoundingBox(positions);
};

const increaseBoundingBox = (boundingBox: BoundingBox): BoundingBox => {
    const height = boundingBox.top - boundingBox.bottom;
    const width = boundingBox.left - boundingBox.right;
    const center = {
        latitude: (boundingBox.top + boundingBox.bottom) / TWO,
        longitude: (boundingBox.left + boundingBox.right) / TWO,
    };
    return {
        top: center.latitude + height * BOUNDING_BOX_INCREASE_FACTORS.top,
        left: center.longitude - width * BOUNDING_BOX_INCREASE_FACTORS.left,
        bottom: center.latitude - height * BOUNDING_BOX_INCREASE_FACTORS.bottom,
        right: center.longitude + width * BOUNDING_BOX_INCREASE_FACTORS.right,
    };
};

const MiniMap = (props: MiniMapProps) => {
    const { delivered = false, latestPosition, placeOfLoadingPosition, placeOfDeliveryPosition } = props;

    const boundingBox = delivered
        ? getBoundingBox(placeOfDeliveryPosition)
        : getBoundingBox(placeOfDeliveryPosition, placeOfLoadingPosition, latestPosition);

    const increasedBoundingBox = increaseBoundingBox(boundingBox);

    const radius = calculateMaxBoundingBoxSide(boundingBox) / TWO;

    return (
        <Map
            credentials={credentials}
            language={'de'}
            boundingBox={increasedBoundingBox}
            hideMapSettings
            disableBehavior
            enableWebGL
        >
            {placeOfLoadingPosition && (
                <>
                    {featureToggles.stopDetectionRadius && (
                        <Circle
                            position={{ lat: placeOfLoadingPosition.latitude, lng: placeOfLoadingPosition.longitude }}
                            radius={Number.parseInt(featureToggles.stopDetectionRadius)}
                            precision={30}
                        />
                    )}
                    <Marker
                        position={{ lat: placeOfLoadingPosition.latitude, lng: placeOfLoadingPosition.longitude }}
                        icon={<SingleMapMarker iconNames={['start']} markerColor='bg-map-marker-route' />}
                    />
                </>
            )}
            {placeOfDeliveryPosition && (
                <>
                    {featureToggles.stopDetectionRadius && (
                        <Circle
                            position={{ lat: placeOfDeliveryPosition.latitude, lng: placeOfDeliveryPosition.longitude }}
                            radius={Number.parseInt(featureToggles.stopDetectionRadius)}
                            precision={30}
                        />
                    )}
                    <Marker
                        position={{ lat: placeOfDeliveryPosition.latitude, lng: placeOfDeliveryPosition.longitude }}
                        icon={
                            <SingleMapMarker
                                iconNames={[delivered ? 'ok' : 'finish']}
                                markerColor='bg-map-marker-route'
                            />
                        }
                    />
                </>
            )}
            {!delivered && latestPosition && (
                <>
                    <Circle
                        position={{ lat: latestPosition.latitude, lng: latestPosition.longitude }}
                        radius={radius}
                        style={{ strokeColor: 'rgba(0, 0, 0, 0)', fillColor: 'rgba(0, 0, 0, 0.3)' }}
                        precision={30}
                    />
                    <Marker
                        position={{ lat: latestPosition.latitude, lng: latestPosition.longitude }}
                        icon={<SingleMapMarker iconNames={['truck']} markerColor='bg-map-marker-asset' />}
                    />
                </>
            )}
        </Map>
    );
};

export default MiniMap;
